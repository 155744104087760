.header-container {
  display: flex;
  justify-content: space-between;
  overflow-y: overlay;
}

.width-third {
  width: 33%;
}

.nav-list {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.nav-list > li {
  margin-right: 1rem;
}

.nav-selected {
  font-weight: bold;
}
